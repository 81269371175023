import React, { useEffect } from "react";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { signInWithGoogle } from "../../firebase";
import { useLoginStore } from "../../Store/loginStore";
import { useAuth } from "../../auth/AuthContext";

function LoginModal(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, currentUser, setCurrentUser } = useAuth();
  const { isLogin, OnClose } = useLoginStore();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    // Perform login logic here
    login(email, password);
  };

  const handleGoogleLogin = () => {
    // Perform Google login logic here
    const data = signInWithGoogle();
    console.log(data);
    const x = localStorage.getItem("creds");
    if (x !== undefined) {
      setCurrentUser(x);
    }

    console.log(currentUser);
    OnClose();
  };

  if (isLogin === true && !currentUser) {
    return (
      <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
        <div className="bg-white p-8 rounded-xl shadow-md w-96">
          <RxCross2
            className="relative left-72 ml-7 cursor-pointer -top-4 text-xl"
            onClick={OnClose}
          />
          <h2 className="text-2xl font-bold mb-4">Login</h2>
          <div className="mb-4">
            <label className="block mb-2  font-medium" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-medium" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="flex flex-col items-center">
            <button
              className="w-10/12 items-center bg-accent   py-2 px-4 rounded-full hover:scale-110 mb-4"
              onClick={handleLogin}
            >
              Login
            </button>
            <div className="flex justify-center w-full py-2">
              <button
                className="flex py-2.5 pl-4 pr-8 rounded-full justify-start  border-solid border-[2px] border-gray-200 gap-8"
                onClick={handleGoogleLogin}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                  viewBox="0 0 256 262"
                  className="w-5 "
                >
                  <path
                    fill="#4285F4"
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  ></path>
                  <path
                    fill="#34A853"
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  ></path>
                  <path
                    fill="#FBBC05"
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  ></path>
                  <path
                    fill="#EB4335"
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  ></path>
                </svg>
                Continue with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default LoginModal;
