import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  deleteUser,
  onAuthStateChanged,
} from "firebase/auth";
import { ref, set } from "firebase/database";
import { useLoginStore } from "../Store/loginStore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [pageHandler, setPageHandler] = useState(1);
  const { OnClose } = useLoginStore();
  async function signup(email, password) {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    setPageHandler(2);
    setCurrentUser(user.user);
  }
  async function login(email, password) {
    const user = await signInWithEmailAndPassword(auth, email, password);
    setCurrentUser(user.user);
    OnClose();
    return user.user;
  }

  function emailVerification() {
    return sendEmailVerification(auth.currentUser);
  }

  function updateUserEmail(email) {
    return updateEmail(auth.currentUser, email);
  }

  function deActivate() {
    window.localStorage.clear();
    return deleteUser(auth.currentUser);
  }

  function reAuthenticate(password) {
    const credentials = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );
    return reauthenticateWithCredential(auth.currentUser, credentials);
  }

  function updateUserPassword(password) {
    return updatePassword(auth.currentUser, password);
  }

  function logout() {
    window.localStorage.clear();
    setCurrentUser(null);
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  const value = {
    currentUser,
    pageHandler,
    setPageHandler,
    login,
    signup,
    logout,
    resetPassword,
    emailVerification,
    updateUserEmail,
    updateUserPassword,
    reAuthenticate,
    deActivate,
    setCurrentUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
