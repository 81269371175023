import { useState } from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";
import HeadingComponent from "./components/Heading/HeadingComponent";
import LoginModal from "./components/Login/Login";
import Navbar from "./components/Navbar/Navbar";
import Data from "./components/Qr/Data";
import MobileView from "./components/MobileView";
import { AuthProvider } from "./auth/AuthContext";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <AuthProvider>
      <div className="bg-primary">
        <Navbar />
        <div className="flex justify-center mt-20 lg:justify-start ">
          <HeadingComponent heading="QRcode Generator" />
        </div>
        <div className="hidden lg:block ">
          <Data />
        </div>
        <div className="w-full h-full flex justify-center items-center mb-52 mt-20 lg:hidden">
          <MobileView />
        </div>
        <div className="mt-10">
          <Footer />
        </div>
      </div>
      <LoginModal />
    </AuthProvider>
  );
};

export default App;
